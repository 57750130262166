import service from '@/utils/request';

// @Tags SysGroupReport
// @Summary 创建SysGroupReport
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysGroupReport true "创建SysGroupReport"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysGroupReport/createSysGroupReport [post]
export var createSysGroupReport = function createSysGroupReport(data) {
  return service({
    url: "/sysGroupReport/createSysGroupReport",
    method: 'post',
    data: data
  });
};

// @Tags SysGroupReport
// @Summary 删除SysGroupReport
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysGroupReport true "删除SysGroupReport"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /sysGroupReport/deleteSysGroupReport [delete]
export var deleteSysGroupReport = function deleteSysGroupReport(data) {
  return service({
    url: "/sysGroupReport/deleteSysGroupReport",
    method: 'delete',
    data: data
  });
};

// @Tags SysGroupReport
// @Summary 删除SysGroupReport
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除SysGroupReport"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /sysGroupReport/deleteSysGroupReport [delete]
export var deleteSysGroupReportByIds = function deleteSysGroupReportByIds(data) {
  return service({
    url: "/sysGroupReport/deleteSysGroupReportByIds",
    method: 'delete',
    data: data
  });
};

// @Tags SysGroupReport
// @Summary 更新SysGroupReport
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysGroupReport true "更新SysGroupReport"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /sysGroupReport/updateSysGroupReport [put]
export var updateSysGroupReport = function updateSysGroupReport(data) {
  return service({
    url: "/sysGroupReport/updateSysGroupReport",
    method: 'put',
    data: data
  });
};

// @Tags SysGroupReport
// @Summary 用id查询SysGroupReport
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysGroupReport true "用id查询SysGroupReport"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /sysGroupReport/findSysGroupReport [get]
export var findSysGroupReport = function findSysGroupReport(params) {
  return service({
    url: "/sysGroupReport/findSysGroupReport",
    method: 'get',
    params: params
  });
};

// @Tags SysGroupReport
// @Summary 分页获取SysGroupReport列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取SysGroupReport列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysGroupReport/getSysGroupReportList [get]
export var getSysGroupReportList = function getSysGroupReportList(params) {
  return service({
    url: "/sysGroupReport/getSysGroupReportList",
    method: 'get',
    params: params
  });
};